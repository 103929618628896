<template>
    <div>
        <a-spin :spinning="pageLoading" :delay="500" tip="正在加载">
            <a-icon slot="indicator" type="loading" style="font-size: 24px" spin />
            <div class="drafts">
                <div class="drafts-header">
                    <div class="header-input">
                        <a-input class="title-input" v-model="paperData.title" :maxLength="50" placeholder="请输入标题" />
                    </div>
                    <div style="color: rgba(0,0,0,0.45);">
                        <div v-if="!savingStatus">
                            文章将会自动保存
                        </div>
                        <div v-else-if="savingStatus == 'saving'">
                            保存中...
                        </div>
                        <div v-else-if="savingStatus == 'saved'">
                            文章已保存
                        </div>
                        <div v-else-if="savingStatus == 'error'" style="color: #ff4d4f;">
                            文章保存出错
                        </div>
                    </div>
                    <div class="header-operations">
                        <a-button @click="handleGoToMyDraftBox"> 草稿箱 </a-button>
                        <a-popover v-model="publishPopoverVisible" placement="bottomRight" trigger="click">
                            <template slot="content">
                                <a-spin :spinning="publishPopoverLoading">
                                    <div style="width: 650px; ">
                                        <a-form-model ref="publishFormRef" :model="paperData" :rules="publishRules"
                                            :colon="true" :labelCol="{ span: '4' }" :wrapperCol="{ span: '19' }">
                                            <a-form-model-item label="可见范围" prop="visibleRange">
                                                <div>
                                                    <a-checkbox :checked="paperData.visibleRange == 3"
                                                        @change="e => handleVisibleRangeChange(e.target.checked, 3)">
                                                        仅课题组可见
                                                    </a-checkbox>
                                                    <!--   <a-select v-show="paperData.visibleRange == 3" v-model="paperData.groupId" placeholder="请选择课题组" style="width: 188px;margin-left: 24px;"
                                                    dropdownClassName="research-group-dropdown"
                                                    @dropdownVisibleChange="handleResearchGroupDrownVisibleChange"
                                                    @search="handleSearchResearchGroupList"
                                                    :showSearch="true"
                                                    :filter-option="false"
                                                    :allowClear="true">
                                                    <a-select-option v-for="(group, groupIndex) in researchGroupList" :key="group.id">
                                                        {{ group.name }}
                                                    </a-select-option>
                                                    <a-select-option :disabled="true" key="loading">
                                                        <div v-if="isMaxPage">
                                                            已加载全部
                                                        </div>
                                                        <div v-else-if="isResearchGroupListLoading">
                                                        <a-icon slot="indicator" type="loading" style="font-size: 14px" spin />
                                                        <span style="margin-left: 8px;" >正在加载</span></div>
                                                    
                                                    </a-select-option>
                                                </a-select> -->
                                                    <a-select v-show="paperData.visibleRange == 3"
                                                        v-model="paperData.groupId" placeholder="请选择课题组"
                                                        style="width: 188px;margin-left: 24px;"
                                                        @search="handleSearchResearchGroupList" :showSearch="true"
                                                        :filter-option="filterOption" :allowClear="true">
                                                        <a-select-option v-for="(group, groupIndex) in researchGroupList"
                                                            :key="group.researchGroupId">
                                                            {{ group.researchGroupName }}
                                                        </a-select-option>
                                                    </a-select>

                                                </div>
                                                <div>
                                                    <a-checkbox :checked="paperData.visibleRange == 2"
                                                        @change="e => handleVisibleRangeChange(e.target.checked, 2)">
                                                        全部可见
                                                    </a-checkbox>
                                                </div>
                                                <div>
                                                    <a-checkbox :checked="paperData.visibleRange == 1"
                                                        @change="e => handleVisibleRangeChange(e.target.checked, 1)">
                                                        仅自己可见
                                                    </a-checkbox>
                                                </div>
                                            </a-form-model-item>
                                            <a-form-model-item label="绑定话题" prop="topicId">
                                                <a-select v-model="paperData.topicId" :showSearch="true"
                                                    :filter-option="filterOption" :allowClear="true" style="width: 300px;"
                                                    placeholder="请选择话题">
                                                    <a-select-option v-for="(subject, subIndex) in topicList"
                                                        :key="subject.id">
                                                        {{ subject.subjectName }}
                                                    </a-select-option>
                                                </a-select>
                                            </a-form-model-item>
                                            <a-form-model-item label="编辑摘要" prop="summary">
                                                <a-input type="textarea" :auto-size="{ minRows: 3, maxRows: 5 }"
                                                    :maxLength="200" v-model="paperData.summary" placeholder="请输入">
                                                </a-input>
                                            </a-form-model-item>
                                            <a-form-model-item label="上传封面" prop="coverPathCode">
                                                <a-upload accept="image/*" :showUploadList="false" :multiple="false"
                                                    :customRequest="handleFileUpload">
                                                    <div class="cover-div" @click="handleUploadCoverClick" v-if="!coverPath"
                                                        style="background: #ebebeb; display: flex; font-size: 60px; align-items: center; justify-content: center; width: 360px; height: 208px;">
                                                        <a-icon type="plus" />
                                                    </div>
                                                    <div @click="handleUploadCoverClick" class="cover-div" v-else>
                                                        <img style="width: 360px; width: 360px; height: 208px;"
                                                            :src="coverPath" />
                                                    </div>
                                                </a-upload>
                                            </a-form-model-item>
                                            <a-form-model-item label=" " prop="allowedComment" :colon="false">
                                                <a-checkbox :checked="paperData.allowedComment == 1"
                                                    @change="e => handleAllowCommentChange(e.target.checked)">
                                                    允许评论
                                                </a-checkbox>
                                            </a-form-model-item>
                                        </a-form-model>

                                    </div>
                                </a-spin>
                                <div style="border-top: 1px solid #eee;padding-top: 16px;">
                                    <a-button type="plain" :disabled="publishPopoverLoading"
                                        @click="publishPopoverVisible = false"> 取消 </a-button>
                                    <a-button type="primary" :disabled="publishPopoverLoading" style="margin-left: 10px;"
                                        @click="handlePublishPaper"> 确定并发布 </a-button>
                                </div>
                            </template>
                            <template slot="title">
                                <div style="font-size: 18px ;">发布文章</div>
                            </template>
                            <a-button type="primary" style="margin-right: 32px;"> 发布 </a-button>
                        </a-popover>

                    </div>
                </div>
                <div class="drafts-content">
                    <ckeditor :value="paperData.content" editor-url="/ckeditor/ckeditor.js"
                        @namespaceloaded="onNamespaceLoaded" @ready="onEditorReady" @input="onEditorInput" :throttle="500"
                        @focus="onEditorFocus" @blur="onEditorBlur" height=""></ckeditor>
                </div>

            </div>

        </a-spin>
        <!--   <my-upload @crop-success="cropSuccess" @crop-fail="cropShow = false" v-if="cropShow" :width="360" :height="208"
            img-format="jpg" :langExt="cropLangSet" :noCircle="true" langType='zh' :noRotate='false'></my-upload> -->
        <cover-cropper-modal :url="coverUrl" v-if="coverCropperShow" :visible.sync="coverCropperShow" @onUpload="onUpload"
            :paperId="id"></cover-cropper-modal>
        <a-modal :visible="publishSuccessModalVisible" :footer="null" :centered="false" :width="660" title="发布成功" :maskClosable="false">
            <template #closeIcon>
                <span></span>
            </template>
            <div>
                <a-result status="success" size="large">
                    <!--  <template #icon>
                    <svg-icon name="article"  class="article-icon" style="font-size: 228px;"> </svg-icon>
                    <a-icon name="success"></a-icon>
                </template> -->
                    <template #title>
                        你已成功发布
                    </template>
                    <template #subTitle>
                        <a target="_blank" :href="getPostHref" style="color: inherit;">
                            <a-icon type="link" /> 《{{ paperData.title }}》
                        </a>
                    </template>
                    <template #extra>
                        <div>
                           <!--  <a target="_blank" :href="getPostHref" style="color: inherit;"> -->
                                <a-button type="primary" @click="handleGoToPost"> 去查看 </a-button>
                            <!-- </a> -->
                            <a-button style="margin-left: 16px;" type="link" @click="handleShareLink">
                                <a-icon type="share-alt" /> 分享
                            </a-button>
                            <a-button style="margin-left: 16px;" @click="handleCloseWindow">
                                关闭窗口
                            </a-button>
                        </div>
                        <!-- <div style="margin-top: 20px;">
                            <a-button>
                                关闭窗口
                            </a-button>
                        </div> -->
                       <!--  <a-button> 关闭</a-button> -->
                        <!--  <a-button type="primary">
                    Next
                </a-button> -->
                    </template>
                </a-result>
            </div>
            <!-- <template #footer>
            <span></span>
           </template> -->
        </a-modal>
    </div>
</template>

<script>
import CKEditor from 'ckeditor4-vue';
import { computed, h, nextTick, onMounted, ref, watch } from 'vue-demi';
import { updatePaper, addPaper, getPaperInfo, publishPaper, getAllSubjectDropDownList, getAllResearchGroupDropDown, uploadFileToPaper, deletePaperCover, getPaperFileUrl } from '@/api/paperManage.js';
import { message, Modal } from 'ant-design-vue';
import debounce from 'lodash/debounce';
import store from '../../../store';
import { useInfiniteScroll } from '@vueuse/core';
import { getResearchGroup } from '@/api/researchGroup.js'
import myUpload from 'vue-image-crop-upload';
import CoverCropperModal from '../components/coverCropperModal.vue';
import Compressor from 'compressorjs';
export default {
    components: {
        ckeditor: CKEditor.component,
        myUpload,
        CoverCropperModal
    },
    setup(props, context) {
        const content = ref('');
        const editor = ref(null);
        const loading = ref(true);
        const fileUploadPath = ref('/api/papermanage/getPaperFileUrl');
        const onNamespaceLoaded = (CKEDITOR) => {
            /*  const projectId = router.currentRoute.query.projectId;
             const id = router.currentRoute.query.id; */
            //fileUploadPath.value = `/api/experiment/uploadFile?projectId=${projectId}&experimentId=${id}`;
            fileUploadPath.value = `/api/papermanage/getPaperFileUrl`
            CKEDITOR.config.removePlugins = 'image2'
            CKEDITOR.config.filebrowserImageUploadUrl = fileUploadPath.value
            //CKEDITOR.config.uploadUrl = `/api/experiment/getFileUrl`
            CKEDITOR.dom.documentFragment.referrerPolicy = 'no-referrer';
            CKEDITOR.config.extraPlugins = 'uploadimage,simage'
            //CKEDITOR.config.pasteUploadFileApi = '/api/papermanage/uploadfile';
            CKEDITOR.config.allowedContent = true;
            CKEDITOR.config.bodyClass = 'document-editor';
            //CKEDITOR.config.fullPage = true;
            CKEDITOR.config.contentsCss = [
                '/css/pastefromword.css'
            ]
            CKEDITOR.config.height = document.documentElement.clientHeight - 172
            CKEDITOR.config.resize_enabled = false;
            /*  CKEDITOR.config.toolbarGroups = [
                 { name: 'clipboard',   groups: [ 'clipboard', 'undo' ] },
                 { name: 'editing',     groups: [ 'find', 'selection', 'spellchecker' ] },
                 { name: 'links' },
                 { name: 'insert' },
                 { name: 'forms' },
                 { name: 'tools' },
                 { name: 'document',	   groups: [ 'mode', 'document', 'doctools' ] },
                 { name: 'others' },
                 '/',
                 { name: 'basicstyles', groups: [ 'basicstyles', 'cleanup' ] },
                 { name: 'paragraph',   groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ] },
                 { name: 'styles' },
                 { name: 'colors' },
                 { name: 'insert' }
             ] */
            /*  CKEDITOR.addCss(
                 '.cke_upload_uploading img{' +
                 'opacity: 0.3' +
                 '}'
             ); */
            //CKEDITOR.config.width = 700;
            CKEDITOR.addCss('.cke4_uploading_img { opacity: 0.3 }')
            loading.value = false;
        }
        const onEditorReady = (editorInsatnce) => {
            editor.value = editorInsatnce;
            // console.log(editorInsatnce.getMenuItem('tablerow').getItems())
            setContextMenu(editorInsatnce)
            setUploadCommand(editorInsatnce)
            /*  editorInsatnce.addMenuGroup('timeStamp')
             editorInsatnce.addCommand('add YYYY-MM-DD  HH:mm:ss', {
                 exec: function (editor) {
                     // 业务代码
                     editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD  HH:mm:ss')}</p>`)
                 }
             })
             editorInsatnce.addMenuItem('timeStamp', {
                 label: '年月日 时分秒',
                 icon: null,
                 command: 'add YYYY-MM-DD  HH:mm:ss',
                 group: 'timeStamp'
             }) */

            // setContextMenu(editorInsatnce);
            /* editorInsatnce.config.image2_captionedClass = 'image-captioned';
            editor.value = editorInsatnce;
            loading.value = false;
            const projectId = router.currentRoute.query.projectId;
            const id = router.currentRoute.query.id;
            // 如果路由存在项目和实验id，则可以正常上传
            if (projectId && id) {
                fileUploadPath.value = `/api/experiment/uploadFile?projectId=${projectId}&experimentId=${id}`;
                editorInsatnce.config.filebrowserImageUploadUrl = fileUploadPath.value
                editorInsatnce.on('fileUploadRequest', function (evt) {
                    console.log(evt)
                    const fileLoader = evt.data.fileLoader;
                    const re = /image\/
                    if (re.test(fileLoader.file)) {
                        const xhr = fileLoader.xhr;
                        const formData = new FormData();
                        formData.append("file", fileLoader.file);
                        xhr.setRequestHeader('Authorization', 'Bearer '+  localStorage.getItem('Authorization'));
                        xhr.setRequestHeader('X-Authorization', 'Bearer '+  localStorage.getItem('Authorization'))
                        xhr.open('POST', fileUploadPath, true);
                        fileLoader.xhr.send(formData);
                        evt.stop();
                    }
                    else {
                        context.root.$message.error('仅支持上传图片')
                        alert('仅支持上传图片')
                        //fileLoader.update()
                        //evt.abort();
                        evt.stop();
                        evt.cancel();
                    }
                    
                }, null, null, 4)
                editor.value.on('fileUploadResponse', function(evt) {
                    evt.stop();
                    const data = evt.data;
                    console.log(data);
                    console.log(evt)
                }) 
            }
            else {

            }*/
            /* editorInsatnce.format_tags = 'img';
            editorInsatnce.config.format_img = { element: 'img',  attributes: { referrerPolicy: "no-referrer" } }  */
            editorInsatnce.document.referrerPolicy = 'no-referrer'
            // editorInsatnce.config.image2_captionedClass = 'image-captioned'
            /*  editorInsatnce.config.image2_maxSize = {
                 width: 700,
                 height: 9999
             } */
            editorInsatnce.on('fileUploadRequest', async function (evt) {

                evt.stop();
                // 如果当前是新草稿则先保存
                if (!id.value) {
                    await saveAsNewPaper()
                }
                const re = /image\*/;
                const fileLoader = evt.data.fileLoader;
                if (fileLoader.file.type.indexOf('image') !== -1) {
                    const isSizeLimit = (fileLoader.file.size / 1024 / 1024) <= 5;
                    if (isSizeLimit) {
                        /*  const projectId = router.currentRoute.query.projectId;
                         const id = router.currentRoute.query.id; */
                        // const formData = new FormData();
                        const compressRes = await compressImage(fileLoader.file);
                        if (!compressRes.success) {
                            evt.data.fileLoader.message = `图片压缩失败，错误信息： ${compressRes.message}`;
                            evt.data.fileLoader.fire('error');
                            evt.cancel();
                        }
                        const file = compressRes.file;
                        if (fileLoader.file instanceof Blob) {
                            //fileLoader.file = new File([fileLoader.file], `${(new Date()).getTime()}.${fileLoader.file.type.substring(fileLoader.file.type.indexOf('/') + 1).toLowerCase()}`, { type: fileLoader.file.type, lastModified: Date.now() })
                            fileLoader.file = file;
                        }

                        /* const xhr = fileLoader.xhr;
                        const path = `/api/papermanage/uploadfile`;
                        xhr.open('POST', path, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('Authorization'));
                        xhr.setRequestHeader('X-Authorization', 'Bearer ' + localStorage.getItem('X-Authorization'))
                        fileLoader.xhr.send(formData);
 */                     const uploadForm = new FormData();
                        if (fileLoader.file instanceof Blob) {
                            fileLoader.file = file;
                            //fileLoader.file = new File([fileLoader.file], `${(new Date()).getTime()}.${fileLoader.file.type.substring(fileLoader.file.type.indexOf('/') + 1).toLowerCase()}`, { type: fileLoader.file.type, lastModified: Date.now() })
                        }
                        uploadForm.append("file", fileLoader.file);
                        uploadForm.append('paperId', id.value)
                        const uploadRes = await uploadFileToPaper(uploadForm);
                        if (uploadRes.code === 200) {
                            const xhr = fileLoader.xhr;
                            //const path = `paper${id.value}/${uploadRes.data}`;
                            const path = `${id.value}/${uploadRes.data}`
                            xhr.open('POST', `${fileUploadPath.value}?filePath=${encodeURI(path)}`, true);
                            xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('Authorization'));
                            xhr.setRequestHeader('X-Authorization', 'Bearer ' + localStorage.getItem('X-Authorization'))
                            fileLoader.xhr.send();
                        }
                        else {
                            evt.data.fileLoader.message = uploadRes.message;
                            evt.data.fileLoader.fire('error');
                        }
                        /* xhr.open('POST', fileUploadPath.value, true);
                        xhr.setRequestHeader('Authorization', 'Bearer ' + localStorage.getItem('Authorization'));
                        xhr.setRequestHeader('X-Authorization', 'Bearer ' + localStorage.getItem('X-Authorization'))
                        fileLoader.xhr.send(); */
                    }
                    else {
                        evt.data.fileLoader.message = '图片大小不能超过5m';
                        evt.data.fileLoader.fire('error');
                        evt.cancel();
                    }
                }
                else {
                    evt.data.fileLoader.message = '仅允许上传图片';
                    evt.data.fileLoader.fire('error');
                    evt.cancel();
                }
            })
            editorInsatnce.on('fileUploadResponse', function (evt) {
                evt.stop();
                const data = evt.data;
                const responseData = JSON.parse(data.fileLoader.xhr.responseText);
                if (responseData.success) {
                    data.url = responseData.data;
                }
                else {
                    data.message = responseData.message;
                    evt.cancel();
                }
            })
            // 监听粘贴时间，下载外链图片并上传为本站图片
            editorInsatnce.on('paste', async function (evt) {
                let dataTransfer = evt.data.dataTransfer;
                let filesCount = dataTransfer.getFilesCount();
                if (filesCount) {
                    for (let i = 0; i < filesCount; ++i) {
                        const file = dataTransfer.getFile(i);
                        if (file.size / 1024 / 1024 > 5) {
                            editor.value.showNotification('图片大小不能超过5M', ['warning'])
                            evt.cancel();
                            evt.stop();
                        }
                    }
                }
                /*   const getBase64Image = (url) => {
                      return new Promise((resolve, reject) => {
                          let image = new Image();
                          image.src = url + '?=' + Math.random();
                          image.crossOrigin = '*' // 支持跨域图片
                          image.onload = () => {
                              resolve(drawBase64Image(image))
                          }
                      })
                  }
  
                  const drawBase64Image = (img) => {
                      let canvas = document.createElement('canvas')
                      canvas.width = img.width
                      canvas.height = img.height
                      let ctx = canvas.getContext('2d')
                      ctx.drawImage(img, 0, 0, img.width, img.height)
                      let dataURL = canvas.toDataURL('image/png')
                      return dataURL
  
                  }
  
                  const padNumber = ( input ) => {
                      if ( input <= 9 ) {
                          input = '0' + input;
                      }
  
                      return String( input );
                  }
  
                  const getUniqueImageFileName = ( type ) => {
                          let date = new Date(),
                              dateParts = [ date.getFullYear(), date.getMonth() + 1, date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds() ];
  
  
                          return 'image-' + CKEDITOR.tools.array.map( dateParts, padNumber ).join( '' ) + '-' + Math.ceil(Math.random() * 1000) + '.' + type;
                      }
                  let data = evt.data;
                  let tempDoc = document.implementation.createHTMLDocument(''),
                      temp = new CKEDITOR.dom.element(tempDoc.body);
                  temp.data('cke-editable', 1);
  
                  temp.appendHtml(data.dataValue);
                  let imgs = temp.find('img');
                  let fileTools = CKEDITOR.fileTools,
                  uploadUrl = fileTools.getUploadUrl( editorInsatnce.config, 'image' );
                  if(imgs.count() > 0) {
                      evt.stop();
                  }
                  for (let i = 0; i < imgs.count(); ++i) {
                      let img = imgs.getItem(i);
                      const src = img.getAttribute( 'src' );
                      const url = new URL(src);
                      
                      if(url.host == process.env.NODE_ENV === 'production' ? window.location.host : 'saims.zkshare.com') {
                          const base64 =  await getBase64Image(src);
                          img.setAttribute( 'src', base64 );
                         // img.renameNode('SPAN')
                          //img.replace(h('span', {}, base64))
                          let imgFormat = base64.match( /image\/([a-z]+?);/i ),
                              loader;
  
                          imgFormat = ( imgFormat && imgFormat[ 1 ] ) || 'jpg';
  
                          loader = editorInsatnce.uploadRepository.create( base64, getUniqueImageFileName( imgFormat ) );
                          loader.upload( uploadUrl );
  
                          fileTools.markElement( img, 'uploadimage', loader.id );
  
                          fileTools.bindNotifications( editorInsatnce, loader );
                      }
                  }
                 
                  if(imgs.count() > 0) {
                      evt.stop()
                      data.dataValue =  temp.getHtml()
                      editorInsatnce.fire('paste', data) ;
                      evt.stop()
                  }
                   */
                // paperData.value.content = temp.getHtml();

            })
            // 监听粘贴完成事件， 对外链图片拦截并且上传
            editorInsatnce.on('afterPaste', function (evt) {
                const editorDom = editor.value.document;
                const allowImageHost = process.env.NODE_ENV == 'production' ? window.location.host : 'saims.zkshare.com'
                const imgs = editorDom.find('img');
                for (let i = 0; i < imgs.count(); ++i) {
                    const img = imgs.getItem(i);
                    const src = img.getAttribute('src')
                    const url = new URL(src);
                    const isDataSrc = src.substring(0, 5) == 'data:';
                    if (url.host !== allowImageHost && !isDataSrc) {
                        markUploadImage(img, url)
                    }
                }
            })
            loading.value = false;
            if (id.value) {
                getPaperData(id.value);
            }
            else {
                document.title = `编辑草稿 - ${paperData.value.title}`
                // 监控数据变化，防抖保存
                watch(() => paperData.value, debounce((newVal) => {
                    if (isLoadingPaper.value) {
                        return
                    }
                    if (savingStatus.value == 'saving') {
                        return
                    }
                    if (!id.value) {
                        saveAsNewPaper()
                    }
                    else {
                        savingPaper()
                    }
                }, 1000), {
                    deep: true
                })
            }
        }

        // 对本地上传/外链图片进行压缩
        const compressImage = async (file) => {
            console.log({
                file: file,
                message: 'before compressing'
            })
            return new Promise((resolve, reject) => {
                const compresser = new Compressor(file, {
                    convertSize: 2000000,
                    success(file) {
                        console.log({
                            message: '压缩成功',
                            file: file
                        })
                        return resolve({
                            success: true,
                            file: new File([file], 'temp.jpeg', { type: 'image/jpeg' })
                        })
                    },
                    error(error) {
                        return resolve({
                            success: false,
                            message: typeof error === 'string' ? error : JSON.stringify(error)
                        })
                    }
                })
            })
        }

        // 标记上传外链的图片
        const markUploadImage = async (img, src) => {
            if (!id.value) {
                await saveAsNewPaper()
            }
            let fileTools = CKEDITOR.fileTools,
                uploadUrl = fileTools.getUploadUrl(editor.value.config, 'image');
            // img.setAttribute('src', '/uploading.png');
            //editor.value && editor.value.fire('change')
            let success = true;
            const file = await getImage(src).catch(error => {
                message.error(`上传外链图片失败，请先下载到本地再上传！ 错误信息： ${typeof error == 'string' ? error : JSON.stringify(error)}`);
                success = false;
            });
            if (!success) {
                img.remove();
                return;
            }
            let imgFormat = 'jpg';
            let loader = editor.value.uploadRepository.create(file);
            loader.upload(uploadUrl);
            loader.on('uploading', function () {
                console.log('uploading')
                //img.addClass('cke_upload_uploading')
            })
            loader.on('error', function (evt) {
                // console.log(evt)
                img.remove();
            })
            loader.on('uploaded', function (evt) {
                img.setAttribute('src', evt.sender.responseData.url)
                img.setAttribute('data-cke-saved-src', evt.sender.responseData.url)
                img.removeClass('cke4_uploading_img')
                img.removeAttribute('data-widget')
                editor.value && editor.value.fire('change')
            })
            img.addClass('cke4_uploading_img')
            fileTools.markElement(img, 'uploadimage', loader.id);
            fileTools.bindNotifications(editor.value, loader);
        }

        const getImage = async (src) => {
            return new Promise((resolve, reject) => {
                let blob = null;
                let xhr = new XMLHttpRequest();
                xhr.open('GET', src);
                xhr.setRequestHeader('Accept', 'image/jpeg');
                xhr.setRequestHeader('Access-Control-Allow-Origin', '*');
                xhr.responseType = 'blob';
                xhr.onload = async () => {
                    blob = xhr.response;
                    let imgFile = new File([blob], 'temp.jpeg', { type: 'image/jpeg' });
                    /*     const res = await compressImage(imgFile);
                        if (res.success) {
                            resolve(res.file);
                        }
                        else {
                            reject(res.message);
                        } */
                    resolve(imgFile)
                }
                xhr.onerror = (e) => {
                    reject(e)
                };
                xhr.send();
            })
        }

        const allHrefMap = () => {

        }

        const getImageUrl = async (path) => {
            const pathRes = await getPaperFileUrl({
                filePath: path
            })
            if (pathRes.code === 200) {
                data.url = pathRes.data;
                console.log(data)
            }
            else {
                data.message = pathRes.message;
                evt.cancel();
            }
        }
        const url = ref('https://saims.zkshare.com/file341229342462021/341229420802117/3515176645345921217.png')

        const value = ref('')

        const onEditorInput = (value) => {
            /* context.emit('update:customData', value) */
            paperData.value.content = value;
        }

        const onEditorFocus = () => {
            // store.dispatch('experiment/setEditor', editor.value);
        }

        const onEditorBlur = () => {
            // store.dispatch('experiment/setEditor', null);
        }
        const getDateStamp = (format) => {
            return dayjs().format(format);
        }
        // 设置上传图片
        const setUploadCommand = (editorInsatnce) => {
            editorInsatnce.addCommand('customUploadImage', {
                exec: function (editor) {
                    let a = document.createElement('input')
                    a.setAttribute('type', 'file')
                    // a.setAttribute('accept', '.jpg,.jpeg,.png,.tif,.gif,.svg')
                    a.setAttribute('accept', 'image/*');
                    a.click();
                    a.onchange = async function () {
                        let file = a.files[0];
                        loading.value = true;
                        if (file.type.indexOf('image') !== -1) {
                            const isSizeLimit = (file.size / 1024 / 1024) <= 5;
                            if (!id.value) {
                                await saveAsNewPaper()
                            }
                            if (isSizeLimit) {
                                const uploadForm = new FormData();
                                const compressRes = await compressImage(file);
                                if (compressRes.success) {
                                    uploadForm.append("file", compressRes.file);
                                    uploadForm.append('paperId', id.value)
                                    const uploadRes = await uploadFileToPaper(uploadForm);
                                    if (uploadRes.code === 200) {
                                        //const path = `paper${id.value}/${uploadRes.data}`;
                                        const path = `${id.value}/${uploadRes.data}`;
                                        const urlRes = await getPaperFileUrl({
                                            filePath: path
                                        })
                                        if (urlRes.success) {
                                            const imgElem = '<img src="' + urlRes.data + '">'
                                            /*  const img = new CKEDITOR.dom.element( 'img' );
                                             img.setAttribute('src', url); */
                                            editor.insertHtml(imgElem)
                                        }
                                    }
                                }
                                else {
                                    message.error(compressRes.message);
                                }

                            }
                            else {
                                message.error('图片大小不能超过5m')
                            }
                        }
                        else {
                            message.error('仅允许上传图片')
                        }
                        loading.value = false;
                    }
                }
            })
        }

        // 设置右键菜单时间戳选项
        const setContextMenu = (editorInsatnce) => {
            // 年月日 时分秒 时间戳
            editorInsatnce.addCommand('add YYYY-MM-DD  HH:mm:ss', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD  HH:mm:ss')}</p>`)
                }
            })
            editorInsatnce.addCommand('add YYYY-MM-DD  HH:mm', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD  HH:mm')}</p>`)
                }
            })
            editorInsatnce.addCommand('add YYYY-MM-DD', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('YYYY-MM-DD')}</p>`)
                }
            })
            editorInsatnce.addCommand('add HH:mm:ss', {
                exec: function (editor) {
                    // 业务代码
                    editor.insertHtml(`<p>${getDateStamp('HH:mm:ss')}</p>`)
                }
            })
            editorInsatnce.addMenuGroup('timeStamp')

            editorInsatnce.addMenuItems({
                timeStamp_1: {
                    label: '年月日 时分秒',
                    command: 'add YYYY-MM-DD  HH:mm:ss',
                    group: 'timeStamp'
                },
                timeStamp_2: {
                    label: '年月日 时分',
                    command: 'add YYYY-MM-DD  HH:mm',
                    group: 'timeStamp'
                },
                timeStamp_3: {
                    label: '年月日',
                    command: 'add YYYY-MM-DD',
                    group: 'timeStamp'
                },
                timeStamp_4: {
                    label: '时分秒',
                    command: 'add HH:mm:ss',
                    group: 'timeStamp'
                }
            })

            editorInsatnce.addMenuItem('timeStamp', {
                label: '时间戳',
                group: 'timeStamp',
                getItems: function () {
                    return {
                        timeStamp_1: 2,
                        timeStamp_2: 2,
                        timeStamp_3: 2,
                        timeStamp_4: 2
                    }
                }
            })


            editorInsatnce.contextMenu.addListener(function (element) {
                // 如果是在图片上右键，不显示该右键菜单
                if (element.$.localName !== "img") {
                    return {
                        timeStamp: 2
                    }
                }
            })
        }

        // 帖子数据
        const paperData = ref({
            title: "",
            content: "",
            visibleRange: 2,
            //state: 1,
            groupId: undefined,
            summary: "",
            coverPathCode: "",
            topicId: undefined,
            allowedComment: 1,  // 是否允许评论
        })

        // 发布form实例
        const publishFormRef = ref();

        const { $route, $router } = context.root;

        const id = ref('')

        const isLoadingPaper = ref(false);  // 是否正在加载草稿
        /*  // 监控数据变化，防抖保存
         watch(() => paperData.value, debounce((newVal) => {
             if (isLoadingPaper.value) {
                 return
             }
             if (savingStatus.value == 'saving') {
                 return
             }
             if (!$route.query.id) {
                 saveAsNewPaper()
             }
             else {
                 savingPaper()
             }
         }, 1000), {
             deep: true
         }) */

        // 获取草稿详情
        const getPaperData = async (id) => {
            isLoadingPaper.value = true;
            const res = await getPaperInfo({
                paperid: id
            })
            if (res.success) {
                paperData.value = {
                    title: res.data.paperInfo.title,
                    content: res.data.paperInfo.content,
                    visibleRange: res.data.paperInfo.visibleRange === 0 ? undefined : res.data.paperInfo.visibleRange,
                    //state: res.data.paperInfo.state === 0 ? undefined : res.data.paperInfo.state,
                    groupId: res.data.paperInfo.groupId === 0 ? undefined : res.data.paperInfo.groupId,
                    summary: res.data.paperInfo.summary,
                    coverPathCode: res.data.paperInfo.coverPathCode,
                    topicId: res.data.paperInfo.topicId === 0 ? undefined : res.data.paperInfo.topicId,
                    allowedComment: res.data.paperInfo.allowedComment,
                }
                isLoadingPaper.value = false;
                getCoverPath();
                document.title = `${paperData.value.title} - 草稿`
                // 监控数据变化，防抖保存
                watch(() => paperData.value, debounce((newVal) => {
                    document.title = `编辑草稿 - ${paperData.value.title}`
                    if (isLoadingPaper.value) {
                        return
                    }
                    if (savingStatus.value == 'saving') {
                        return
                    }
                    if (!$route.query.id) {
                        saveAsNewPaper()
                    }
                    else {
                        savingPaper()
                    }
                }, 1000), {
                    deep: true
                })
                /* setTimeout(() => {
                    isLoadingPaper.value = false;
                }) */

                /* $router.replace({
                    name: 'bbsDrafts',
                    query: {
                        id: res.data
                    }
                }) */
            }
            else {
                Modal.error(res.message);
            }

        }

        /*  const isSaving = ref(false);    // 是否正在保存
 
         const isSavingError = ref(false);    // 是否保存失败
 
         const isSavingSuccess = ref(false) */

        const savingStatus = ref('');


        // 保存新帖子
        const saveAsNewPaper = async () => {
            savingStatus.value = 'saving';
            const res = await addPaper({
                title: paperData.value.title,
                content: paperData.value.content,
                visibleRange: paperData.value.visibleRange === undefined ? 0 : paperData.value.visibleRange,
                // state: paperData.value.state,
                groupId: paperData.value.groupId === undefined ? undefined : paperData.value.groupId,
                summary: paperData.value.summary,
                coverPathCode: paperData.value.coverPathCode,
                topicId: paperData.value.topicId === undefined ? 0 : paperData.value.topicId,
                allowedComment: paperData.value.allowedComment,
                //id: $route.query.id
            });
            if (res.success) {
                savingStatus.value = 'saved';
                id.value = res.data
                $router.replace({
                    name: 'bbsDrafts',
                    query: {
                        id: res.data
                    }
                })
                document.title = `${paperData.value.title} - 草稿`
            }
            else {
                Modal.error({
                    title: typeof res.message == 'object' ? JSON.stringify(res.message) : res.message
                });
                savingStatus.value = 'error';
            }
        }

        // 保存现有帖子
        const savingPaper = async (forceSave = false) => {
            // 不是强制保存时： 如果正在保存，或者正在发布， 或者已发布，取消保存
            if(!forceSave && (savingStatus.value == 'saving' || isSubmitting.value || publishSuccessModalVisible.value )) {
                return ;
            }
            savingStatus.value = 'saving';
            const res = await updatePaper({
                title: paperData.value.title,
                content: paperData.value.content,
                visibleRange: paperData.value.visibleRange === undefined ? 0 : paperData.value.visibleRange,
                //state: paperData.value.state,
                groupId: paperData.value.groupId === undefined ? 0 : paperData.value.groupId,
                summary: paperData.value.summary,
                coverPathCode: paperData.value.coverPathCode,
                topicId: paperData.value.topicId === undefined ? 0 : paperData.value.topicId,
                allowedComment: paperData.value.allowedComment,
                id: id.value
            })
            if (res.success) {
                savingStatus.value = 'saved'
                document.title = `${paperData.value.title} - 草稿`
            }
            else {
                Modal.error({
                    title: res.message
                });
                savingStatus.value = 'error';
            }
            return Promise.resolve(res);
        }

        // 页面是否正在加载
        const pageLoading = computed(() => {
            return isLoadingPaper.value || loading.value;   // 正在加载草稿数据或加载CKEditor
        })

        onMounted(() => {
            document.title = `编辑草稿 - ${paperData.value.title}`
            id.value = $route.query.id
            /*  if (id.value) {
                 getPaperData(id.value);
             }
             else {
                 document.title = `编辑草稿 - ${paperData.value.title}`
                 // 监控数据变化，防抖保存
                 watch(() => paperData.value, debounce((newVal) => {
                     if (isLoadingPaper.value) {
                         return
                     }
                     if (savingStatus.value == 'saving') {
                         return
                     }
                     if (!id.value) {
                         saveAsNewPaper()
                     }
                     else {
                         savingPaper()
                     }
                 }, 1000), {
                     deep: true
                 })
             } */
        })

        // 发布对话框是否正在加载
        const publishPopoverLoading = computed(() => {
            return isResearchGroupListLoading.value || isTopicLoading.value || isSubmitting.value;
        });

        // 发布popover可见变量
        const publishPopoverVisible = ref(false);

        // 课题组列表
        const researchGroupList = ref([]);

        // 课题组总数
        const researchGroupCount = ref(0);

        // 课题组总页数
        const researchGroupTotalPage = ref(2);

        // 课题组页数
        const researchGroupPageNo = ref(1);

        // 课题组单页数量
        const researchGroupPageSize = ref(15);
        // 是否正在加载课题组列表
        const isResearchGroupListLoading = ref(false);

        const researchGroupNameSearchVal = ref('')

        const filterOption = (input, option) => {
            return (
                option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            );
        }

        const isMaxPage = computed(() => {
            return researchGroupPageNo.value >= researchGroupTotalPage.value;
            /* const maxPage = res
            return Math.ceil( ) */
        })

        // 话题下拉数据是否正在加载
        const isTopicLoading = ref(false);

        // 话题列表数据
        const topicList = ref([]);

        // 获取话题列表
        const getTopicList = async () => {
            isTopicLoading.value = true;
            /* const res = await searchSubjectRecord({});
            if(res.success) {
                topicList.value = res.data.rows;
            } */
            const res = await getAllSubjectDropDownList();
            if (res.success) {
                topicList.value = res.data;
            }
            isTopicLoading.value = false;
        }


        watch(() => publishPopoverVisible.value, async (val) => {
            if (val) {
                getResearchGroupList();
                getTopicList();
                if (paperData.value.summary == '') {

                    const text = editor.value.document.getBody().getText().trim()
                    if (text.length < 200) {
                        paperData.value.summary = text.toString().trim();
                    }
                    else {
                        paperData.value.summary = text.toString().trim().substring(0, 200)
                    }
                }
            }
        })

        // 获取课题组列表
        const getResearchGroupList = async () => {
            /*   if(isMaxPage.value) {
                  return ;
              } */
            isResearchGroupListLoading.value = true;
            /*  const res = await getResearchGroup({
                 pageNo: researchGroupPageNo.value,
                 pageSize: researchGroupPageSize.value,
                 searchBeginTime: undefined,
                 searchBeginTime: undefined,
                 status: '',
                 name: researchGroupNameSearchVal.value
             });
             if(res.success) {
                 researchGroupTotalPage.value = res.data.totalPage;
                 researchGroupList.value.splice(researchGroupList.value.length, 0, ...res.data.rows);
                 researchGroupPageNo.value = researchGroupPageNo.value + 1;
                 researchGroupList.value = res.data.rows;
             }
             else {
                 
             } */
            const res = await getAllResearchGroupDropDown();
            if (res.success) {
                researchGroupList.value = res.data
            }
            isResearchGroupListLoading.value = false;
        }
        // 搜索防抖
        const searchDobounce = debounce(getResearchGroupList, 500);

        // 课题组输入框搜索
        const handleSearchResearchGroupList = (val) => {
            researchGroupList.value = [];
            researchGroupNameSearchVal.value = val;
            //researchGroupCount.value = 0;
            researchGroupTotalPage.value = 2;
            researchGroupPageNo.value = 1;
            isResearchGroupListLoading.value = true
            searchDobounce();
        }

        // 课题组下拉可见事件
        const handleResearchGroupDrownVisibleChange = async (val) => {
            if (val) {
                await getResearchGroupList();
                setTimeout(() => {
                    nextTick(() => {
                        useInfiniteScroll(ref(document.querySelector('.research-group-dropdown > .ant-select-dropdown-content > .ant-select-dropdown-menu')), () => {
                            console.log('trick')
                        })
                    })
                }, 0);

            }
        }

        // 发布帖子的规则
        const publishRules = ref({
            visibleRange: [
                {
                    required: true,
                    message: '请选择可见范围',
                    trigger: []
                },
                {
                    validator: (_rule, value, calback) => {
                        if (value == 0) {
                            calback(new Error('请选择可见范围'))
                        }
                        else if (value == 3) {
                            if (!paperData.value.groupId) {
                                calback(new Error('请选择课题组'))
                            }
                            else {
                                calback()
                            }
                        }
                        calback();
                    },
                    trigger: []
                }
            ],
            summary: [
                {
                    required: true,
                    message: '请编辑摘要',
                    trigger: []
                }
            ]
        })

        // 可见范围改变事件
        const handleVisibleRangeChange = (checked, statu) => {
            if (paperData.value.visibleRange != statu && checked) {
                if (paperData.value.visibleRange == 3) {
                    paperData.value.groupId = undefined
                }
                paperData.value.visibleRange = statu;
            }
            else if (paperData.value.visibleRange == statu && !checked) {
                if (paperData.value.visibleRange == 3) {
                    paperData.value.groupId = undefined
                }
                paperData.value.visibleRange = 0
            }
            paperData.value.groupId = undefined
            /* console.log({
                checked,
                statu
            }) */
        }

        // 跳转至我的课题组
        const handleGoToMyDraftBox = () => {
            const href = $router.resolve({
                name: 'bbsUserDrafts',
                params: {
                    id: 0
                }
            })
            window.open(href.href, '_blank')
        }

        const isSubmitting = ref(false);    // 是否正在提交

        // 发布提交
        const handlePublishPaper = () => {
            publishFormRef.value && publishFormRef.value.validate(async (valid) => {
                if (valid) {
                    isSubmitting.value = true;
                    try {
                        const formData = new FormData();
                        formData.append('paperid', id.value)
                        const saveRes = await savingPaper(true);
                        if (!saveRes.success) {
                            isSubmitting.value = false;
                            return;
                        }
                        const res = await publishPaper(formData);
                        if (res.success) {
                            publishPopoverVisible.value = false;
                            publishSuccessModalVisible.value = true;
                            /* Modal.success({
                                content: '文章成功发布',
                                onOk: () => {
                                    window.close();
                                    $router.replace({
                                        name: 'bbsUserPosts',
                                        params: {
                                            id: 0
                                        }
                                    })
                                }
                            }) */
                        }
                    } catch (error) {

                    }
                    isSubmitting.value = false;
                }
            })
        }

        // 设置用户是否允许评论
        const handleAllowCommentChange = (checked) => {
            paperData.value.allowedComment = checked ? 1 : 2
        }

        /**********************************************************            封面剪裁                      ************************************/
        const cropSuccess = async (imgDataUrl, field) => {
            /* 
                        const res = await uploadFileToPaper({
            
                        }) */
            paperData.value.coverPathCode = imgDataUrl
            cropShow.value = false;
            publishPopoverVisible.value = true;
            getCoverPath();
        }

        /*   const coverPath = computed( async () => {
  
          }) */

        const cropLangSet = ref({
            hint: '点击，或拖动图片至此处',
            loading: '正在上传……',
            noSupported: '浏览器不支持该功能，请使用IE10以上或其他现在浏览器！',
            success: '上传成功',
            fail: '图片上传失败',
            preview: '封面预览',
            btn: {
                off: '取消',
                close: '关闭',
                back: '上一步',
                save: '保存'
            },
            error: {
                onlyImg: '仅限图片格式',
                outOfSize: '单文件大小不能超过 ',
                lowestPx: '图片最低像素为（宽*高）：'
            }
        }
        )

        const cropShow = ref(false);

        const handleUploadCoverClick = () => {
            cropShow.value = true
        }


        /***********************************  上传封面      *********************/
        const coverUrl = ref('')
        const coverCropperShow = ref(false);
        const handleFileUpload = async ({ action, file, onSuccess, onError, onProgress }) => {

            if (file.type.indexOf('image/') === -1) {
                message.error('结构式仅支持上传图片文件');
                return;
            }
            if (!id.value) {
                await saveAsNewPaper();
            }
            coverUrl.value = window.URL.createObjectURL(file);
            publishPopoverVisible.value = false;
            coverCropperShow.value = true;

        }
        // 上传封面回调
        const onUpload = async (url) => {
            if (paperData.value.coverPathCode) {
                await deleteCover();
            }
            paperData.value.coverPathCode = url;
            getCoverPath();
            coverCropperShow.value = false;
            publishPopoverVisible.value = true;
        }

        const isLoadingCoverPath = ref(false);

        const coverPath = ref('');

        // 发布成功对话框可见变量
        const publishSuccessModalVisible = ref(false);

        // 获取封面路径
        const getCoverPath = async () => {
            if (paperData.value.coverPathCode) {
                isLoadingCoverPath.value = true;
                const res = await getPaperFileUrl({
                    //filePath: `paper${id.value}/${paperData.value.coverPathCode}`
                    filePath:  `${id.value}/${paperData.value.coverPathCode}`
                });
                if (res.success) {
                    coverPath.value = res.data

                }
            }
            else {
                coverPath.value = '';
            }

            isLoadingCoverPath.value = false;
        }
        // 删除原封面
        const deleteCover = async () => {
            const formData = new FormData();
            formData.append('paperId', id.value)
            await deletePaperCover(formData)
        }

        // 点击分享按钮
        const handleShareLink = async () => {
            const isSupportted = await authentication();
            if (isSupportted) {
                const href = $router.resolve({
                    name: 'bbsPost',
                    params: {
                        id: $route.query.id
                    }
                })
                navigator.clipboard.writeText(`${window.location.origin}/${href.href}`);
                message.info('已成功复制链接，去分享给朋友吧！')
            }
        }

        // 确定是否拥有复制权限
        const authentication = () => {
            if ("clipboard" in navigator) {
                return new Promise((resolve, reject) => {
                    navigator.permissions.query({ name: "clipboard-read" }).then(
                        (result) => {
                            if (result.state == "granted" || result.state == "prompt") {
                                resolve(true);
                            } else {
                                resolve(false);
                            }
                        },
                        (error) => {
                            reject(error);
                        }
                    );
                });
            } else {
                alert("该浏览器暂不支持，请使用最新版本的GoogleChrome浏览");
                return Promise.resolve(false);
            }
        };

        // 解析获取帖子链接
        const getPostHref = computed(() => {
            const href = $router.resolve({
                name: 'bbsPost',
                params: {
                    id: id.value
                }
            });
            return href.href;
        })

        // 关闭窗口
        const handleCloseWindow = () => {
            window.close();
        }

        // 点击去查看已发布的帖子
        const handleGoToPost = () => {
            $router.replace({
                name: 'bbsPost',
                params: {
                    id: id.value
                }
            })
        }

        return {
            onEditorReady,
            loading,
            onNamespaceLoaded,
            value,
            url,
            onEditorInput,
            onEditorFocus,
            onEditorBlur,
            content,
            savingStatus,
            pageLoading,
            paperData,
            publishPopoverVisible,
            publishRules,
            handleVisibleRangeChange,
            handleGoToMyDraftBox,
            handleResearchGroupDrownVisibleChange,
            researchGroupList,
            researchGroupCount,
            researchGroupPageNo,
            researchGroupPageSize,
            publishPopoverLoading,
            isResearchGroupListLoading,
            isMaxPage,
            handleSearchResearchGroupList,
            handlePublishPaper,
            publishFormRef,
            filterOption,
            topicList,
            handleAllowCommentChange,
            cropSuccess,
            cropShow,
            handleUploadCoverClick,
            cropLangSet,
            handleFileUpload,
            coverCropperShow,
            coverUrl,
            onUpload,
            id,
            getCoverPath,
            coverPath,
            handleShareLink,
            getPostHref,
            handleCloseWindow,
            handleGoToPost,
            publishSuccessModalVisible
        }
    }
}
</script>

<style lang="less" scoped>
.drafts {
    min-height: 99vh;

    .drafts-header {
        padding-right: 20px;
        display: flex;
        align-items: center;
        border-bottom: .5px solid #EBEBEB;
        padding: 16px 20px 16px 0px;

        .header-input {
            width: 0;
            flex: 1;

            .title-input {
                border: none;
                font-size: 28px;

                &:focus,
                &:active {
                    border: none;
                    box-shadow: none;
                }
            }
        }

        .header-operations {
            margin-left: 32px;

            .ant-btn+.ant-btn {
                margin-left: 20px;
            }
        }
    }

}

.cover-div {
    border: 1px solid #ebebeb;

    &:hover {
        opacity: 0.8;
    }

    cursor: pointer;
}

/* /deep/ .image-captioned {
    max-width: 700px;
} */
</style>