<template>
    <a-modal :visible="visible" :width="800" title="剪裁封面" @cancel="handleCancel">
        <a-spin :spinning="uploading" tip="正在上传">
            <div style="height: 516px; width: 720px;">
                <VueCropper
                ref="cropper"
                :fixed="true"
                :fixedNumber="[540, 314]"
                :fixedBox="true"
                :autoCropWidth="540"
                :autoCropHeight="314"
                :img="url"
                :outputSize="1"
                :outputType="'png'"
                :infoTrue="true"
                :auto-crop="true"
                ></VueCropper>
            </div>
        </a-spin>
        <template slot="footer">
            <div style="display: flex; justify-content: center;">
                <a-button type="primary" @click="handleCropAndUpload" :loading="uploading"  :disabled="uploading"> 剪裁并上传 </a-button>
                <a-button @click="handleCancel" :loading="uploading" :disabled="uploading"> 取消 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { VueCropper }  from 'vue-cropper' 
import { ref } from 'vue-demi';
import { uploadFileToPaper } from '@/api/paperManage.js';
import { compressImage } from '@/utils/utils.js';
import { message } from 'ant-design-vue';
export default {
    props: {
        visible: {
            default: false
        },
        url: {
            default: ''
        },
        paperId: {
            default: ''
        }
    },
    components: {
        VueCropper
    },
    setup(props, context) {
        const cropper = ref();
        const handleCropAndUpload = () => {
            cropper.value && cropper.value.getCropBlob( async (data) => {
                uploading.value = true;
                const uploadForm = new FormData();
                const compressRes = await compressImage(data);
                if(!compressRes.success) {
                    message.error(`上传图片失败，失败信息${compressRes.message}`);
                    uploading.value = false;
                    return ;
                }
                const file = compressRes.file;
                uploadForm.append('paperid', props.paperId);
                uploadForm.append('file', file );
                const res = await uploadFileToPaper(uploadForm);
                if(res.success) {
                    context.emit('onUpload', res.data);
                }
                
               /*  if(res.success) {
                    const dres = await getFileUrl({
                        filePath: `paper/${props.paperId}/${res.data}`
                    })
                    if(dres.success) {
                        context.emit('onUpload', dres.data);
                    }
                    
                } */
                uploading.value = false;
            })
        }
        const handleCancel = () => {
            context.emit('update:visible', false)
        }
        const uploading = ref(false);
        return {
            cropper,
            handleCropAndUpload,
            handleCancel,
            uploading
        }
    }
}
</script>